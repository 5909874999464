import {Fragment} from 'react';
import {BrowserRouter as Router, Routes, Route} from 'react-router-dom';
import Home from './components/Home';
import Contact from './components/Contact';
import Navigation from './components/Navigation';

const App = () => {
	return (
		<div>
			<Router>
				<Navigation />
				<div className='container'>
					<Routes>
						<Route exact path='/' element={<Home />} />
						<Route exact path='/contact' element={<Contact />} />
					</Routes>
				</div>
			</Router>
		</div>
	);
};

export default App;
