const Footer = () => {
	return (
		<footer className='blue-background border border-primary'>
			<div className='row'>
				<div className='col-md-12'>
					<div className='d-flex justify-content-center flex-wrap tech'>
						<div className='p-4 display-6'>
							<a id='github' href='https://github.com/nashawn-griffith' target='_blank'>
								<i className='fab fa-github'></i>
							</a>
						</div>

						<div className='p-4 display-6 '>
							<a id='linkedin' href='https://www.linkedin.com/in/nashawn-griffith-3a248a162/' target='_blank'>
								<i className='fab fa-linkedin'></i>
							</a>
						</div>

						<div className='p-4 display-6 '>
							<a id='twitter' href='https://twitter.com/boonkz' target='_blank'>
								<i className='fab fa-twitter'></i>
							</a>
						</div>
					</div>
					<div>
						<p className='text-center'>
							Copyright <span>&copy; </span> Nashawn Griffith {new Date().getFullYear()}
						</p>
					</div>
				</div>
			</div>
		</footer>
	);
};

export default Footer;
