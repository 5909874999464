import React, {Fragment} from 'react';
import {BrowserRouter as Router, Switch, Route, NavLink} from 'react-router-dom';
import logo from '../assets/favicon_custom.ico';

const Navigation = () => {
	return (
		<Fragment>
			<nav className='navbar navbar-expand-sm navbar-dark bg-dark'>
				<div className='container'>
					<NavLink to='/' className='navbar-brand'>
						{/* Home */}
						<img src={logo} />
					</NavLink>
					<button
						className='navbar-toggler'
						type='button'
						data-bs-toggle='collapse'
						data-bs-target='#navbarNav'
						aria-controls='navbarNav'
						aria-expanded='false'
						aria-label='Toggle navigation'
					>
						<span className='navbar-toggler-icon'></span>
					</button>

					<div className='collapse navbar-collapse' id='navbarNav'>
						<ul className='navbar-nav ms-auto'>
							<li className='nav-item'>
								<NavLink to='/' className='nav-link'>
									Home
								</NavLink>
							</li>
							<li className='nav-item'>
								<NavLink to='/contact' className='nav-link'>
									Contact
								</NavLink>
							</li>
						</ul>
					</div>
				</div>
			</nav>
		</Fragment>
	);
};

export default Navigation;
