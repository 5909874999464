import {Fragment} from 'react';
import AboutMe from './AboutMe';
import Skills from './Skills';
import Contact from './Contact';
import Introduction from './Introduction';
import Experience from './Experience';
import Footer from './Footer';

const Home = () => {
  return (
    <Fragment>
      <Introduction />
      <AboutMe />
      <Skills />
      <Experience />
      <Footer />
    </Fragment>
  );
};

export default Home;
