import React, {Fragment} from 'react';

const Experience = () => {
	return (
		<Fragment>
			<div className='border rounded'>
				<section className='blue-background p-3 text-light text-center'>
					<h3 className=' display-6 '>Skills</h3>
				</section>

				<section>
					<ul className='list-group'>
						<li className='list-group-item '>
							<p className='lead'>API development with Node.JS and the Express framework</p>
						</li>
						<li className='list-group-item '>
							<p className='lead'>Containerize Applications using Docker</p>
						</li>
						<li className='list-group-item '>
							<p className='lead'>
								Work with different AWS services. See some examples below <br />
								- Lambda <br />
								- API Gateway <br />
								- DynomoDB <br />
								- S3 <br />
								- SQS <br />
								- SNS <br />
								- Fargate <br />
							</p>
						</li>
						<li className='list-group-item '>
							<p className='lead'>Develop Serverless Applications using AWS Serverless Application Model (SAM) and CloudFormation</p>
						</li>
						<li className='list-group-item'>
							<p className='lead'>
								Build continuous integration and deployment (CI/CD) pipelines with tools such as <strong>AWS CodePipeline </strong> and
								<strong> Github Actions </strong>
							</p>
						</li>
					</ul>
				</section>
			</div>

			{/* <hr className='mt-0' style={{borderBottom: ' #008ed6 10px  solid'}} /> */}
		</Fragment>
	);
};

<section>
	<div className='col-md-12 blue-background text-light'>
		<h3 className='display-6 text-center  '>Tech I Use</h3>
	</div>
</section>;
export default Experience;
