import {Fragment} from 'react';

const Skills = () => {
	return (
		<Fragment>
			<section className='blue-background p-3 text-light text-center'>
				<h3 className='display-6 '>Technologies </h3>
			</section>

			<section className='bg-dark'>
				<div className='row'>
					<div className='  text-light d-flex justify-content-center flex-wrap '>
						<div className='p-4 m-4 display-3 '>
							<p className='lead '>JavaScript </p>
							<i className='fab fa-js ms-3'></i>
						</div>

						<div className='p-4 m-4 display-3 '>
							<p className='lead '>Node Js </p>
							<i className='fab fa-node-js'></i>
						</div>

						<div className='p-4 m-4 display-3 '>
							<p className='lead ms-3'>AWS </p>
							<i className='fab fa-aws'></i>
						</div>

						<div className='p-4 m-4 display-3'>
							<p className='lead '>Docker </p>
							<i className='fab fa-docker'></i>
						</div>
					</div>
				</div>
			</section>
		</Fragment>
	);
};

export default Skills;
