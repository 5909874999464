import React, {Fragment} from 'react';
import display from '../assets/nashawn.jpg';

const Introduction = () => {
	//container was here
	return (
		<Fragment>
			<div className='card bg-dark'>
				<div className='row g-0'>
					<div className='col-md-2  '>
						<img src={display} className=' border-0 img-fluid' alt='...' />
					</div>
					<div className='col-md-10 ps-3'>
						<div className='card-body text-light'>
							<div className='card-text'>
								<p className='fs-5'>
									<strong>Name:</strong> <em> Nashawn Griffith </em>
								</p>
								<p className='fs-5'>
									<strong>Occupation:</strong> <em> Software Engineer </em>
								</p>
								<p className='fs-5'>
									<strong>Location:</strong> <em> Barbados </em>
								</p>
							</div>
						</div>
					</div>
				</div>
			</div>
		</Fragment>
	);
};

export default Introduction;
