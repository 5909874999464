import axios from 'axios';
import {useState} from 'react';
import Footer from './Footer';

const Contact = () => {
	const [userName, setName] = useState('');
	const [userEmail, setUserEmail] = useState('');
	const [userSubject, setUserSubject] = useState('');
	const [userMessage, setUserMessage] = useState('');
	const [isError, setError] = useState(false);
	const [resultMessage, setResultMessage] = useState('');
	const [isLoading, setLoading] = useState(false);

	const sendEmail = async emailParams => await axios.post('https://email-api.nashawng.com/email', JSON.stringify(emailParams));

	const showAlert = () => {
		if (isError && !isLoading && resultMessage) {
			return (
				<div className='alert alert-danger' role='alert'>
					{resultMessage}
				</div>
			);
		}

		if (!isError && !isLoading && resultMessage) {
			return (
				<div className='alert alert-success' role='alert'>
					{resultMessage}
				</div>
			);
		}
	};

	const validateInput = ({name, email, subject, message}) => {
		let inputError = {message: ''};

		/*
      Adding Some Validation 
    */
		if (name.trim().length === 0) {
			throw {
				...inputError,
				message: 'Please enter your name',
			};
		}

		if (email.trim().length === 0) {
			throw {
				...inputError,
				message: 'Please enter your email',
			};
		}

		if (subject.trim().length === 0) {
			throw {
				...inputError,
				message: 'Please enter the subject of your message',
			};
		}

		if (message.trim().length === 0) {
			throw {
				...inputError,
				message: 'Your message cannot be empty',
			};
		}
	};

	const onSubmitHandler = async e => {
		e.preventDefault();

		try {
			const messageObject = {
				name: userName,
				email: userEmail,
				subject: userSubject,
				message: userMessage,
			};

			validateInput(messageObject);

			//Input is valid.. Sent Email
			setLoading(true);
			const result = await sendEmail(messageObject);

			if (!result.data?.message) {
				throw new Error('Something Went Wrong');
			}

			setLoading(false);
			setError(false);
			setResultMessage(`${result.data.message}`);
			setName('');
			setUserEmail('');
			setUserSubject('');
			setUserMessage('');
			setTimeout(() => {
				setResultMessage('');
			}, 3000);
		} catch (err) {
			console.log(err);
			setLoading(false);
			setError(true);

			if (err.response?.data?.message) {
				setResultMessage(`${err.response.data.message}`);
			} else if (err.message) {
				setResultMessage(err.message);
			} else {
				setResultMessage('Error. Please try again later');
			}
			setTimeout(() => {
				setResultMessage('');
			}, 3000);
		}
	};

	const nameOnChangeHandler = e => {
		const enteredName = e.target.value;
		setName(enteredName);
	};

	const emailOnChangeHandler = e => {
		const enteredEmail = e.target.value;
		setUserEmail(enteredEmail);
	};

	const subjectOnChangeHandler = e => {
		const enteredSubject = e.target.value;
		setUserSubject(enteredSubject);
	};

	const messageChangeHandler = e => {
		const enteredEmail = e.target.value;
		setUserMessage(enteredEmail);
	};

	return (
		<div>
			<section className='blue-background p-3 text-light text-center'>
				<h3 className='display-6 '>Let's Connect </h3>
			</section>
			<hr />
			{showAlert()}

			<form onSubmit={onSubmitHandler}>
				<div className='mb-3'>
					<label htmlFor='userName' className='form-label'>
						Name
					</label>
					<input onChange={nameOnChangeHandler} type='text' className='form-control' id='userName' value={userName} placeholder='Enter Name' />
				</div>

				<div className='mb-3'>
					<label htmlFor='userEmail' className='form-label'>
						Email
					</label>
					<input
						onChange={emailOnChangeHandler}
						type='email'
						className='form-control'
						id='userEmail'
						aria-describedby='emailHelp'
						value={userEmail}
						placeholder='Enter Email'
					/>
				</div>

				<div className='mb-3'>
					<label htmlFor='subject' className='form-label '>
						Subject
					</label>
					<input
						onChange={subjectOnChangeHandler}
						type='text'
						className='form-control'
						id='subject'
						value={userSubject}
						placeholder='Enter Subject'
					/>
				</div>

				<div className='mb-3'>
					<label htmlFor='message' className='form-label'>
						Message
					</label>
					<textarea
						onChange={messageChangeHandler}
						id='message'
						className='form-control'
						value={userMessage}
						rows='10'
						cols='50'
						placeholder='Enter Text Here'
					></textarea>
				</div>

				<button type='submit' className='btn btn-primary'>
					Submit
				</button>
			</form>
			<hr />
			<Footer />
		</div>
	);
};

export default Contact;
