import React, {Fragment} from 'react';
import display from '../assets/nashawn.jpg';

const AboutMe = () => {
  return (
    <Fragment>
      <section className=' blue-background p-3 text-light text-center'>
        <h3 className='display-6 '>About Me</h3>
      </section>

      <section className='white-background text-muted p-3'>
        <div className='row'>
          <div className='col-md-12'>
            <p className='lead'>
              Unknown to most people, I was born on the island of Barbados but
              spent from my childhood to adult years in Grenada. However, at age
              twenty-four (24), I made the decision to return to Barbados in
              order to pursue a Bachelors of Science (BSc.) in Computer Science.
              My journey so far has been an interesting one. Initially, my aim
              was to have a career in Electrical or Computer Engineering given
              my passion for Mathematics, Physics and Chemistry but this all
              changed due to very unforeseen circumstances.
            </p>

            <p className='lead'>
              Whilst viewing a television series which contained a Computer
              Programmer that assisted the protagonists, the term “C++” was
              mentioned and this immediately piqued my interest. As such, I
              started to conduct research and sought out as much knowledge as I
              could in order to increase my knowledge base on the topic. Through
              guidance from tutorials I found on the internet, I eventually
              wrote my first “Hello World” program and from that point on, my
              love for coding grew further and I continued to build more simple
              programs.
            </p>

            <p className='lead'>
              Subsequently, I armed myself with additional knowledge through a
              textbook which spoke in great detail about the language associated
              with C++ and this increased passion was the catalyst for me
              deciding that a career based on Computer Science was what I
              wanted.
            </p>

            <p className='lead'>
              I started my BSc. in 2016 and when I completed my studies three
              years later, I was offered a summer internship to work as a
              Backend Web Developer. In January 2020, I began to work full-time
              as a Developer and have been enjoying the journey ever since.
            </p>
          </div>
        </div>
      </section>
    </Fragment>
  );
};

export default AboutMe;
